@use '@angular/material' as mat;

// Documentation
// https://eduardoboucas.github.io/include-media/documentation/#variable-breakpoints
// @import 'node_modules/include-media/dist/_include-media.scss';
// $breakpoints: (
//   'phone': 320px,
//   'tablet-sm': 534px,
//   'tablet': 768px,
//   'desktop': 1024px
// );

@include mat.core();
// @import url("../src/assets/assets/fonts/use.typekit.net/jnq3uxd.css");


$custom-light-primary: (
  50: #fff3e0,
  100: #ffe0b2,
  200: #ffcc80,

  300: #ffb74d,
  400: #ffa726,
  500: #ff9800,
  600: #fb8c00,
  700: #f57c00,
  800: #ef6c00,
  900: #e65100,
  A100: #ffd180,
  A200: #ffab40,
  A400: #ff9100,
  A700: #ff6d00,
  contrast: (50: #575757,
    100: #575757,
    200: #575757,
    300: #575757,
    400: #575757,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: #575757,
    A200: #575757,
    A400: #575757,
    A700: white,
  )
);


$custom-dark-primary: (
  50: #f8f0e2,
  100: #eedab7,
  200: #e2c187,
  300: #d6a856,
  400: #ce9632,
  500: #c5830e,
  600: #bf7b0c,
  700: #b8700a,
  800: #b06608,
  900: #a35304,
  A100: #ffe3ce,
  A200: #ffc79b,
  A400: #ffaa68,
  A700: #ff9c4e,
  contrast: (50: #575757,
    100: #575757,
    200: #575757,
    300: #575757,
    400: #575757,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: #575757,
    A200: #575757,
    A400: #575757,
    A700: white,
  )
);

// $custom-dark-primary: (
//   50: #fde6cf,
//   100: #e2c3ac,
//   200: #c19d84,
//   300: #9f795c,
//   400: #875e3e,
//   500: #6e4521,
//   600: #643c1c,
//   700: #553014,
//   800: #48240d,
//   900: #391600,
//   A100: #ffd180,
//   A200: #ffab40,
//   A400: #ff9100,
//   A700: #ff6d00,
//   contrast: (50: #575757,
//     100: #575757,
//     200: #575757,
//     300: #575757,
//     400: #575757,
//     500: 143955,
//     600: 143955,
//     700: 143955,
//     800: 143955,
//     900: 143955,
//     A100: #575757,
//     A200: #575757,
//     A400: #575757,
//     A700: 143955,
//   )
// );

$custom-dark-secondary: (
  50: #e5e9ed,
  100: #bdc9d3,
  200: #93a6b6,
  300: #6c8499,
  400: #4b6b85,
  500: #275374,
  600: #204c6d,
  700: #184262,
  800: #143955,
  900: #13283c,
  A100: #ffd180,
  A200: #ffab40,
  A400: #ff9100,
  A700: #ff6d00,
  contrast: (50: #575757,
    100: #575757,
    200: #575757,
    300: #575757,
    400: #575757,
    500: 143955,
    600: 143955,
    700: 143955,
    800: 143955,
    900: 143955,
    A100: #575757,
    A200: #575757,
    A400: #575757,
    A700: 143955,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$dark-primary: mat.define-palette($custom-dark-primary);
$dark-accent: mat.define-palette($custom-dark-secondary);

// $font-family: sofia-pro,
// $font-family: "'Courier New', Courier, monospace",
// $font-family: 'Work Sans, arial, helvetica, sans-serif',
$my-typography-config: mat.define-typography-config( 
    $font-family: 'Work Sans, arial, helvetica, sans-serif',
    $headline-1: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
    $headline-2: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
    $headline-3: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
    $headline-4: mat.define-typography-level(34px, 40px, 400),
    $headline-5: mat.define-typography-level(24px, 32px, 400),
    $headline-6: mat.define-typography-level(20px, 24px, 500, $letter-spacing: 0.01em),
    $subtitle-1: mat.define-typography-level(16px, 24px, 400, $letter-spacing: 0.01em),
    $subtitle-2: mat.define-typography-level(14px, 20px, 500, $letter-spacing: 0.01em),
    $body-1: mat.define-typography-level(16px, 24px, 400),
    $body-2: mat.define-typography-level(14px, 20px, 400),
    $button: mat.define-typography-level(14px, 14px, 500, $letter-spacing: 0.01em),
    $caption: mat.define-typography-level(12px, 16px, 400),
    $overline: mat.define-typography-level(10px, 16px, 400, $letter-spacing: 0.01em),

  );

$dark-theme: mat.define-dark-theme((color: (primary: $dark-primary,
        accent: $dark-accent ),
      typography: $my-typography-config,
      density: 0,
    ));

// Light colors
$light-custom-theme-primary: mat.define-palette($custom-light-primary);
$light-custom-theme-accent: mat.define-palette(mat.$blue-palette);
$light-custom-theme: mat.define-light-theme((color: (primary: $light-custom-theme-primary,
        accent: $light-custom-theme-accent ),
      typography: $my-typography-config,
      density: 0,
    ));

// Default colors
$default-custom-theme-primary: mat.define-palette(mat.$teal-palette, 700, 100, 800);
$default-custom-theme-accent: mat.define-palette(mat.$teal-palette, 700, 100, 800);
$default-theme: mat.define-light-theme((color: (primary: $default-custom-theme-primary,
        accent: $default-custom-theme-accent ),
      typography: $my-typography-config,
      density: 0,
    ));

.default-theme {
  // Emit theme-dependent styles for common features used across multiple components.
  @include mat.all-component-colors($default-theme);
}

.light-theme {
  @include mat.all-component-colors($light-custom-theme);
}

.dark-theme {
  @include mat.all-component-colors($dark-theme);
}

// All typography styles are generated from the config in the theme definition.
@include mat.all-component-typographies($default-theme);

.mat-icon {
  line-height: 1 !important;
  height: unset !important;
  width: unset !important;
}

// * {
// font-family: 'Courier New', Courier, monospace;
// font-size: 14px;
// font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
// }




.view-container {
  border-radius: 12px;
  border: 1px solid;
  border-color: #c4c6d0;

  .view-header {
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 1rem 1.5rem 0 1.5rem;

    .viewer-title-spacer {
      flex: 1 1 auto;
    }

  }

  .view-content {
    padding: 1rem 1.5rem;
  }

}