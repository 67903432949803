@use '@angular/material' as mat;

.density-down-1 {
  @include mat.all-component-densities(-1);
}

.density-down-2 {
  @include mat.all-component-densities(-2);
}

.density-down-3 {
  @include mat.all-component-densities(-3);
}
