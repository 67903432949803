/* You can add global styles to this file, and also import other style files */

// Application wide layout adjustments.

html,
body {
    height: 100%;
}
body {
    margin: 0;
    // font-family: Roboto, "Helvetica Neue", sans-serif;
}

.graph-overlay {
    fill: none;
    stroke: none;
    pointer-events: all;
}

.focusCircle {
    fill: red;
}

.focusLine {
    fill: none;
    stroke: #aaa;
    stroke-width: 0.5px;
}

.graph-circle {
    fill: white;
    stroke-width: 20px;
    stroke: steelblue;
}

.tooltip-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1001;

    // Propagate cursor events to the graph overlay.
    pointer-events: none;
}

.graph-tooltip {
    background: lightsteelblue;
    border-radius: 8px;
    border: 0px;
    display: flex;
    font: 12px sans-serif;
    max-width: 200px;
    padding: 2px;
    pointer-events: none;
    position: relative;
    text-align: center;
    width: 200px;
    z-index: 1001;
}

.primary-graph-tooltip {
    margin-left: 2rem;
    order: 1;
}

.secondary-graph-tooltip {
    margin-left: 2rem;
    order: 2;
}

.tertiary-graph-tooltip {
    order: 3;
    margin-left: 2rem;
}

.tooltip-date {
    // font-weight: bold;
    // font-size: 0.7rem;
    font-family: 'Arial Black', Gadget, sans-serif;
}

.graph-tooltip-container {
    display: block;
    padding: 4px;
    text-align: left;
    width: 100%;
}

.no-border {
    border: 0px !important;
}

// TODO: move to module scope, remove from global scope
.alert {
    background-color: cornflowerblue;
    bottom: 0 !important;
    color: black;
    position: absolute !important;
    right: 20px;
    z-index: 99999999 !important;
}

.warning-icon {
    color: #ffcc00;
    vertical-align: middle;
}

.link {
    color: #007bff;
    text-decoration: none;
    cursor: pointer;

    mat-icon {
        // vertical-align: middle;
    }

    &:hover {
        text-decoration: underline;
    }

    &.active {
        color: #007bff;
    }

    &.disabled {
        color: #6c757d;
        cursor: not-allowed;
    }

    &.disabled:hover {
        text-decoration: none;
    }

    &.disabled.active {
        color: #6c757d;
    }

    &.disabled.active:hover {
        text-decoration: none;
    }
}

// Flex lay out classes.

.flex-spacer {
    flex: 1 1 auto;
    justify-content: center;
    display: flex;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

// CDK styling

/* .ck.ck-editor__main>.ck-editor__editable
{
	border-color: var(--ck-color-base-border);
	position:absolute;
	height: 80%!important;
	width:100%;
}
.ck-editor{
	width: 100%!important;
	min-width:100px!important;
	height: 200px!important;
} */
div.cdx-simple-image__caption {
    display: none;
}
/** editor.js css **/
.cdx-block,
.ce-block__content {
    max-width: 73em;
    width: 70em;
}
.ce-toolbar__plus {
    position: relative;
    left: -15em;
}
.codex-editor {
    padding-top: 5px;
}

// Old Styles effecting the Material layout display

// #search {
// 	position: fixed;
// 	top: 0px;
// 	left: 0px;
// 	width: 100%;
// 	height: 100%;
// 	background-color: rgba(0, 0, 0, 0.7);
// 	-webkit-transition: all 0.5s ease-in-out;
// 	-moz-transition: all 0.5s ease-in-out;
// 	-o-transition: all 0.5s ease-in-out;
// 	-ms-transition: all 0.5s ease-in-out;
// 	transition: all 0.5s ease-in-out;
// 	-webkit-transform: translate(0px, -100%) scale(0);
// 	-moz-transform: translate(0px, -100%) scale(0, 0);
// 	-o-transform: translate(0px, -100%) scale(0, 0);
// 	-ms-transform: translate(0px, -100%) scale(0, 0);
// 	transform: translate(0px, -100%) scale(0);
// 	z-index: 99999;
// 	opacity: 0;
// }
// a, button {
// 	outline: none !important;
// 	text-decoration: none !important;
// 	color: #868e96;
// 	transition: all 0.2s ease 0s;
// }
// #search .close {
// 	position: fixed;
// 	top: 15px;
// 	right: 15px;
// 	color: #fff;
// 	background-color: #428bca;
// 	border-color: #357ebd;
// 	opacity: 1;
// 	padding: 10px 17px;
// 	font-size: 27px;
// }
// #search input[type='search'] {
// 	position: absolute;
// 	top: 50%;
// 	width: 100%;
// 	color: #ffffff;
// 	background: rgba(0, 0, 0, 0);
// 	font-size: 60px;
// 	font-weight: 300;
// 	text-align: center;
// 	border: 0px;
// 	margin: 0px auto;
// 	margin-top: -51px;
// 	padding-left: 30px;
// 	padding-right: 30px;
// 	outline: none;
// }
// .btn-primary {
// 	background: #5873fe;
// 	border-color: #5873fe;
// 	color: #ffffff;
// }

// #search .btn {
// 	position: absolute;
// 	top: 50%;
// 	left: 50%;
// 	margin-top: 61px;
// 	margin-left: -45px;
// }
// .row {
// 	margin-left: -7.5px;
// 	margin-right: -7.5px;
// }
// .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
// 	padding-top: 7.5px;
// 	padding-bottom: 7.5px;
// }
// .card {
// 	background: #ffffff;
// 	margin: 7.5px 0;
// 	padding: 20px;
// 	border: 1px solid #e7e7e7;
// 	border-radius: 3px;
// 	box-shadow: 0px 5px 20px rgba(0,0,0,0.1);
// }

// .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6.h1 {
// 	color: #373757;
// }

// p {
// 	font-family: "Roboto",sans-serif;
// 	color: #868e96;
// }

// .drop-down {
// 	background: #ffffff;
// 	color: #252525;
// 	visibility: hidden;
// 	opacity: 0;
// 	width: 320px;
// 	position: absolute;
// 	right: 0px;
// 	top: 55px;
// 	transform: translateY(50px);
// 	transition: all 0.4s ease-in-out;
// 	border-top: 0;
// 	border: 1px solid #e7e7e7;
// 	z-index: 999;
// }
// .dropdown-profile {
// 	width: 200px;
// }
// .dropdown-content-heading {
// 	padding: 10px 15px;
// }
// .dropdown-content-body li {
// 	padding: 15px;
// 	border-top: 1px solid #eef5f9;
// }
// .dropdown-profile li {
// 	padding: 7px 15px;
// }
// .dropdown-content-body li:last-child {
// 	padding: 5px 15px;
// }
// .dropdown-profile li:last-child {
// 	padding: 7px 15px;
// }
// .dropdown-profile li a {
// 	display: block;
// 	color: #373757;
// }
// .dropdown-profile li a i {
// 	margin-right: 5px;
// 	font-size: 14px;
// }
// .dropdown-profile li a span {
// 	font-family: "Roboto",sans-serif;
// 	font-size: 14px;
// }
// .dropdown-content-heading span {
// 	font-size: 13px;
// 	font-family: "Roboto",sans-serif;
// 	color: #373757;
// }

// .sidebar.sidebar-light .nano-content>ul>li:hover>a {
//     color: #474747;
// }

// .dib {
// 	display: inline-block;
// }
// .user-avatar {
// 	font-size: 14px;
// 	font-weight: 700;
// }

// @media screen and (max-width: 480px)
// {
// 	.hamburger{
// 		display:none!important;
// 	}

// 	li.header-icon{
// 		padding:0px!important;
// 		margin:0px!important;
// 		float:right!important;
// 		font-size:1em!important;
// 	}
// }

.overflow-clip {
    overflow: clip !important;
}

.tooltip-tx-type {
    font-weight: bold;
    // font-size: 14px;
    font-family: 'Arial Black', Gadget, sans-serif;
}
