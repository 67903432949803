/* You can add global styles to this file, and also import other style files */

html,
body {
  // font-family: Roboto, 'Helvetica Neue', sans-serif;

  &.dark-theme {
    background-color: #303030;
  }
}

.dark-theme {
  .form-blink-animation {
    position: relative;

    // Use pseudo element to create a mask that will flash when the input is focused.
    &::after {
      // Animation to flash the background color and set it to clear again.
      animation: backgroundClear 700ms cubic-bezier(0.42, 1.95, 0.51, -1.26);
      animation-fill-mode: forwards;
      background: #424242; // Dark theme.
      border-radius: 4px;
      content: '';
      display: block;
      height: calc(100% + 1rem); // 1 rem to include the label.
      position: absolute;
      top: -1rem; // -1 rem to include the label.
      width: 100%;
      z-index: 10; // Increase if required.
    }
  }
}

// Default Theme = Light Theme.
.light-theme,
.default-theme {
  .form-blink-animation {
    position: relative;

    // Use pseudo element to create a mask that will flash when the input is focused.
    &::after {
      // Animation to flash the background color and set it to clear again.
      animation: backgroundClear 700ms cubic-bezier(0.42, 1.95, 0.51, -1.26);
      animation-fill-mode: forwards;
      background: #ffffff; // Light theme.
      border-radius: 4px;
      content: '';
      display: block;
      height: calc(100% + 1rem); // 1 rem to include the label.
      position: absolute;
      top: -1rem; // -1 rem to include the label.
      width: 100%;
      z-index: 10; // Increase if required.
    }
  }
}

.invalid-icon {
  color: crimson;
  line-height: 22px !important;
}

.valid-icon {
  color: green;
  line-height: 22px !important;
}

.warn-icon {
  color: orange;
  line-height: 22px !important;
}

// --------------------------------------------------
// =============== Animation classes. ===============
// --------------------------------------------------

@keyframes backgroundClear {
  to {
    background: transparent;
  }
}

@keyframes pulse {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    transform: scaleX(1);
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// --------------------------------------------------
// ============== Flex helper classes. ==============
// --------------------------------------------------

.flex-just-between {
  justify-content: space-between;
}

.flex-space-between {
  display: flex;
  flex: 1 auto;
  align-items: center;
  justify-content: space-between;
}

.flex-nowrap {
  display: flex;
  flex-wrap: nowrap;
}

.flex-spacer {
  flex: 1 1 auto;
}

.flex-center {
  display: flex;
  flex: 1 auto;
  align-items: center;
  justify-content: center;
}

.flex-items-center {
  display: flex;
  align-items: center;
}

.flex-right {
  display: flex;
  flex: 1 auto;
  align-items: center;
  justify-content: flex-end;
}

.flex-left {
  display: flex;
  flex: 1 auto;
  align-items: center;
  justify-content: flex-start;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

// --------------------------------------------------
// ============= Bootstrap-ish classes. =============
// --------------------------------------------------

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.mr-5 {
  margin-right: 2rem;
}

.mr-4 {
  margin-right: 1.5rem;
}

.mr-3 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-0 {
  margin-right: 0 !important;
}

.ml-5 {
  margin-left: 2rem;
}

.ml-4 {
  margin-left: 1.5rem;
}

.ml-3 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-0 {
  margin-left: 0 !important;
}

.mb-5 {
  margin-bottom: 2rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-5 {
  margin-top: 2rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-0 {
  margin-top: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mx-3 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-4 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-5 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-5 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.m-5 {
  margin: 2rem;
}

.m-4 {
  margin: 1.5rem;
}

.m-3 {
  margin: 1rem;
}

.m-2 {
  margin: 0.5rem;
}

.m-1 {
  margin: 0.25rem;
}

.m-0 {
  margin: 0 !important;
}

.m-0-auto {
  margin: 0 auto !important;
}

.m-025-auto {
  margin: 0.25rem auto !important;
}

.m-05-auto {
  margin: 0.5rem auto !important;
}

.p-5 {
  padding: 2rem;
}

.p-4 {
  padding: 1.5rem;
}

.p-3 {
  padding: 1rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-1 {
  padding: 0.25rem;
}

.p-0 {
  padding: 0 !important;
}

.py {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-4 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.px-5 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-4 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.pr-5 {
  padding-right: 2rem;
}

.pr-4 {
  padding-right: 1.5rem;
}

.pr-3 {
  padding-right: 1rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pr-1 {
  padding-right: 0.25rem;
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-5 {
  padding-left: 2rem;
}

.pl-4 {
  padding-left: 1.5rem;
}

.pl-3 {
  padding-left: 1rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pl-0 {
  padding-left: 0 !important;
}

.pt-5 {
  padding-top: 2rem;
}

.pt-4 {
  padding-top: 1.5rem;
}

.pt-3 {
  padding-top: 1rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-0 {
  padding-top: 0 m !important;
}

.pb-5 {
  padding-bottom: 2rem;
}

.pb-4 {
  padding-bottom: 1.5rem;
}

.pb-3 {
  padding-bottom: 1rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.w-0 {
  width: 0 !important;
}

.h-100 {
  height: 100%;
}

.h-75 {
  height: 75%;
}

.h-50 {
  height: 50%;
}

.h-25 {
  height: 25%;
}

.h-0 {
  height: 0 !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-fill {
  flex: 1 1 auto;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-1-auto {
  flex: 1 auto;
}

.flex-1 {
  flex: 1;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.p-abs {
  position: absolute;
}

.p-rel {
  position: relative;
}

.p-sticky {
  position: sticky;
}

.o-0 {
  opacity: 0;
}

.o-25 {
  opacity: 0.25;
}

.o-50 {
  opacity: 0.5;
}

.o-75 {
  opacity: 0.75;
}

.o-100 {
  opacity: 1;
}

// --------------------------------------------------
// =========== Bootstrap-ish classes End. ===========
// --------------------------------------------------
